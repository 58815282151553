












































































































































































































































































































































































import { Component, Ref, Vue } from 'vue-property-decorator'
import { MenuIcon, XIcon, UserIcon, ExternalLinkIcon } from '@vue-hero-icons/solid'
import { BellIcon, ShoppingCartIcon, ChatAltIcon, CalendarIcon } from '@vue-hero-icons/outline'
import { Watch } from 'nuxt-property-decorator'
import { UsersApi, MessagesApi, AtSchoolsApi } from '~/apis'
import BadgeIcon from '~/components/BadgeIcon.vue'
import { AtSchoolTabDto, FromPage, MenuDto } from '~/types'
import FlyoutMenu from '~/components/FlyoutMenu.vue'
import mixpanel from '~/plugins/mixpanel'

@Component({
  components: {
    FlyoutMenu,
    BellIcon,
    MenuIcon,
    XIcon,
    UserIcon,
    ShoppingCartIcon,
    BadgeIcon,
    ChatAltIcon,
    CalendarIcon
  }
})
export default class Header extends Vue {
  show = false
  showMobile = false
  outsideClickListener: null | any = null
  @Ref('myMenu') readonly myMenu!: HTMLDivElement
  now = Date.now()
  openFlyoutMenu: boolean = false
  studentCareSubMenus = [
    {
      path: '/student-care',
      label: 'Student care home'
    },
    {
      path: '/student-care-curriculum',
      label: 'Curriculum'
    },
    {
      path: '/student-care-technology',
      label: 'Technology'
    },
    {
      path: '/student-care-pricing',
      label: 'Pricing'
    },
    {
      path: '/student-care-faqs',
      label: 'FAQs'
    },
    {
      path: 'https://offers.agora-colearning.space/agora-student-care-siglap',
      label: 'Siglap location',
      openNewTab: true,
      component: ExternalLinkIcon
    }
  ]

  earlyYearSubMenus = [
    {
      path: '/preschool-playschool',
      label: 'Preschool'
    },
    {
      path: '/preschool-playschool/the-agora-method',
      label: 'The Agora Method'
    },
    {
      label: 'Playschool',
      path: '/preschool-playschool/playschool'
    },
    {
      label: 'Gallery',
      path: '/preschool-playschool/gallery'
    },
    {
      path: '/preschool-playschool/pricing',
      label: 'Pricing'
    },
    {
      path: '/preschool-playschool/faqs',
      label: 'FAQs'
    }
  ]

  hostAnEventWithUsSubMenus = [
    {
      path: '/host-an-event-with-us',
      label: 'Host an event with us'
    },
    {
      path: '/host-an-event-with-us/gallery',
      label: 'Gallery'
    },
    {
      path: '/host-an-event-with-us/faqs',
      label: 'FAQs'
    }
  ]

  moreSubMenus = [
    {
      path: '/about',
      label: 'About us'
    },
    // {
    //   path: '/host-an-event-with-us/',
    //   label: 'Host an event'
    // },
    {
      path: '/blog',
      label: 'Blog & news'
    },
    {
      path: '/faqs',
      label: 'FAQ'
    },
    {
      path: '/getting-there',
      label: 'Getting here'
    }
  ]

  navigationRoutes: MenuDto[] = [
    {
      path: '/courses',
      label: 'Enrichment Classes'
    },
    {
      label: 'Student Care',
      path: '/student-care',
      isExpanded: false,
      subMenu: this.studentCareSubMenus
    },
    {
      label: 'Preschool & Playschool',
      path: '/preschool-playschool',
      isExpanded: false,
      subMenu: this.earlyYearSubMenus
    },
    {
      path: '/host-an-event-with-us',
      label: 'Celebrations & Events',
      isExpanded: false,
      subMenu: this.hostAnEventWithUsSubMenus
    },
    {
      path: '/blog',
      label: 'Blog'
    }
  ]

  scrollY: number = 0
  isDisplayNoneVtab: boolean = false

  setIsDisplayNoneVtab () {
    const element = document.getElementsByClassName('v-slide-group__prev v-slide-group__prev--disabled')[0]
    return !element
  }

  get authenticated () {
    return this.$store.state.authenticated
  }

  get avatar () {
    return this.$store.state.user?.avatar
  }

  get badgeItems () {
    return this.$store.state.shoppingCart.badgeItems
  }

  get messageUnreadCount () {
    return this.$store.state.messageUnreadCount
  }

  get isHomePage () {
    return this.$route.path === '/home' || this.$route.path === '/home/' || this.$route.name === 'index'
  }

  get isAtSchoolCoursesPage () {
    return this.$route.name === 'schoolSlug'
  }

  get isNotIndexPage () {
    return this.$route.name !== 'index'
  }

  get isProfilePage () {
    return (this.$route.path.includes('/profile'))
  }

  get isCoursePage () {
    return (this.$route.name === 'courses' || this.$route.name === 'courses-providers-slug')
  }

  get isTermsOfBusinessPage () {
    return this.$route.path.includes('/terms-of-business')
  }

  get isCoursePolicyPage () {
    return this.$route.path.includes('/course-policy')
  }

  login () {
    window.history.pushState({}, '', window.location.href)
    if (this.isAtSchoolUrl && this.$route.params.schoolSlug) {
      this.$router.push(`/sign-in?from=${this.$route.params.schoolSlug}`)
    } else {
      this.$router.push('/sign-in')
    }
  }

  signUp () {
    if (this.isAtSchoolUrl) {
      this.$router.push(`/sign-up?from=${this.$route.params.schoolSlug}`)
    } else {
      this.$router.push('/sign-up')
    }
  }

  get isAtSchoolUrl () {
    return this.$store.state.currentViewFor === FromPage.AtSchool
  }

  async signOut () {
    const currentUser = this.$store.state.user?.sub
    this.$store.commit('setIntercom', null)
    this.$store.commit('cleanShoppingCart', currentUser)
    this.$store.commit('setAuthentication', { authenticated: false, user: null })
    await this.$auth.logout()
    if (process.env.NODE_ENV !== 'local') {
      mixpanel.reset()
    }
    if (this.$store.state.currentViewFor === FromPage.AtSchool) {
      this.$router.push('/sign-in')
    } else {
      this.$router.push('/')
    }
  }

  mounted () {
    this.isDisplayNoneVtab = this.setIsDisplayNoneVtab()
    this.changeHeaderTransparent()
    this.bindOutsideClickListener()
    if (this.$store.state.authenticated) {
      new UsersApi().getUserInfo(this.$store.state.user?.sub as string)
        .then((value) => {
          const user = JSON.parse(JSON.stringify(this.$store.state.user))
          user.avatar = value.data.data.info.avatar
          user.userType = value.data.data.info.userType
          this.$store.commit('setUser', user)
          // this.$auth?.setUser(user)
        })
      if (this.$store.state.currentViewFor === FromPage.AtSchool) {
        new AtSchoolsApi().getSchoolsByParentId()
          .then(({ data: { data: response } }) => {
            const tabs: AtSchoolTabDto[] = []
            response.forEach((headerValue) => {
              tabs.push({
                name: headerValue.name,
                href: `/${headerValue.slug}`,
                image: headerValue.image,
                slug: headerValue.slug
              })
            })
            this.$store.commit('setSchoolsHeader', tabs)
          })
      }
    }
    if (this.$store.state.user && this.$store.state.user?.userType !== 'ep_admin') {
      this.setMessageUnreadCount()
    }
  }

  get tabs (): AtSchoolTabDto[] {
    return this.$store.state.schoolsHeader
  }

  async setMessageUnreadCount () {
    const { data } = await new MessagesApi().getUnreadMessageAmount()
    this.$store.commit('setMessageUnreadCount', data.unreadMessageAmount)
  }

  isActive (path: string) {
    if (this.$route.path.includes('/profile')) {
      return false
    }

    if (path === '/student-care/') {
      return this.$route.path.includes('/student-care')
    }

    return this.$route.path === path ||
      this.$route.path.startsWith(path + '/') ||
      this.$route.path.startsWith(path + '-')
  }

  changeHeaderTransparent () {
    const header = window.document.getElementById('header')
    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY
      if (window.scrollY === 0) {
        header?.classList.remove('tw-shadow-md')
      }
    })
  }

  get logoFontColour () {
    return this.$store.state.styles.logoFontColour
  }

  get mainMenuFontColour () {
    return this.$store.state.styles.mainMenuFontColour
  }

  get isApplyNewStyle () {
    return this.scrollY === 0 && this.isHomePage
  }

  get schoolSlugInStore () {
    return this.$store.state.schoolSlug || ''
  }

  get activeHeaderAtSchool () {
    return this.tabs.filter(tab => this.schoolSlugInStore.includes(tab.href) || (!this.schoolSlugInStore && this.$route.path.includes(tab.href))).length > 0
  }

  bindOutsideClickListener () {
    if (!this.outsideClickListener) {
      this.outsideClickListener = (event: any) => {
        const className: string = event.target.className
        if (this.myMenu && this.show && (typeof className !== 'string' || !className.includes('my-img-user'))) {
          this.show = false
          this.unbindOutsideClickListener()
        }
      }
      document.addEventListener('click', this.outsideClickListener)
    }
  }

  unbindOutsideClickListener () {
    if (this.outsideClickListener) {
      document.removeEventListener('click', this.outsideClickListener)
      this.outsideClickListener = null
    }
  }

  @Watch('$route', { deep: true })
  routeChange () {
    this.showMobile = false
    this.navigationRoutes = this.navigationRoutes.map((e) => {
      e.isExpanded = false
      return e
    })
  }

  routeClick (e: Event, route: MenuDto) {
    const nodeName = (e.target as HTMLElement).closest('.nav-item')!.nodeName
    if (route.subMenu && nodeName === 'DIV') {
      route.isExpanded = !route.isExpanded
    } else {
      this.now = Date.now()
      this.showMobile = !this.showMobile
      this.show = false
      if (!route.subMenu) {
        this.$router.push(route.path)
      }
    }
  }

  toggleFlyoutMenu (route: MenuDto) {
    route.isExpanded = !route.isExpanded
  }

  onSelect (route: string) {
    this.$router.push(route)
  }
}
